<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 拼购详情
 * @Date: 2021-01-21 10:57:55
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-21 15:56:18
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/Shopping_details.vue
-->
<template>
  <div class="content">
    <div class="info_deta">
      <div>{{ teamStatus | Status }}</div>
      <div class="pin_box">
        <div v-for="(item,index) in team" :key="index">
          <img :src="item.headpic" alt="">
          <span v-if="item.type === 1">团长</span>
        </div>
      </div>
      <div class="pin_foot">
        <div @click="goOrderDetails(ordersn)">
          <span>订单详情</span>
          <span>{{ productInfo.goods_name }}</span>
          <van-icon name="arrow" />
        </div>
        <div>
          <span>订单规则</span>
          <span>好友拼单人满发货人不满退款</span>
        </div>
      </div>
    </div>
    <div class="more">
      <div class="more_title">
        一起拼团优惠购
        <div class="more_in" @click="lookGo()">
          查看更多
          <van-icon name="arrow" style="vertical-align: -2px" />
        </div>
      </div>
      <div class="more_ul">
        <div v-for="(item,index) in list" :key="index" @click="details(item)">
          <div class="item_img">
            <img :src="item.goodsImg" alt="">
          </div>
          <div class="item_info">
            <div class="goodsName">{{ item.goodsName }}</div>
            <div class="needer"><span>{{ item.needer }}人拼团只需：</span><span>¥{{ item.teamPrice }}</span></div>
            <div class="originPrice">{{ item.remind }} {{ item.originPrice }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Toast } from 'vant'

Vue.use(Toast).use(Icon)
import { getSpellGroupDescribe } from '@/services/goods'
import { assemble } from '@/services/userApi'

export default {
  filters: {
    Status(v) {
      const map = {
        1: '赶快邀请好友一起拼团吧',
        2: '恭喜你拼团成功',
        3: '该团未能按时凑齐人数，拼团失败'
      }
      return map[v]
    }
  },
  data() {
    return {
      uid: '',
      type: '',
      ordersn: '',
      productInfo: '',
      team: [],
      list: [],
      teamStatus: ''
    }
  },
  created() {
    this.uid = localStorage.getItem('uid')
    this.ordersn = this.$route.query.ordersn
    this.type = this.$route.query.type
    this.getinfo()
    this.getmore()
  },
  methods: {
    // 查看更多
    lookGo() {
      this.$router.push({
        path: '/pagePublic',
        query: {
          temptype: 1
        }
      })
    },

    getinfo() {
      const param = {
        uid: this.uid,
        ordersn: this.ordersn,
        type: this.type
      }
      getSpellGroupDescribe(param).then(res => {
        if (Number(res.code) === 200) {
          this.productInfo = res.data.productInfo
          this.team = res.data.team
          this.teamStatus = res.data.teamStatus
        }
      })
    },
    getmore() {
      const param = {
        channel: 7,
        page: 1
      }
      assemble(param).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data.item
        }
      })
    },
    details(o) {
      const obj = {
        goodId: o.goodsId,
        skuId: o.skuId,
        product_type: 1
      }
      this.$store.dispatch('godetail', obj)
    },
    goOrderDetails(v) {
      this.$router.push({ path: '/Spelldetail', query: { orderNo: v }})
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  background: #f6f6f6;
  padding-bottom: 80px;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .details_info {
    margin: 13px;
    background: #FFFFFF;
    padding: 13px;
    border-radius: 5px;
    display: flex;

    .info_img {
      width: 107px;
      // height: 107px;
      border-radius: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
  }

  .info_deta {
    margin: 13px;
    padding: 13px;
    border-radius: 5px;
    background: #FFFFFF;

    .pin_box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: 15px 0;

      & > div {
        width: 52px;
        height: 52px;
        margin: 15px 20px;
        position: relative;

        & > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        & > span {
          padding: 4px 10px;
          border-radius: 7px;
          background: #E33F44;
          color: #fff;
          position: absolute;
          bottom: -15px;
          left: 0;
          // margin-left: -17px;
        }
      }
    }

    .pin_foot {
      & > div {
        padding: 13px 0;
        display: flex;
        justify-content: space-between;

        & > span:nth-child(1) {
          flex: 3;
          text-align: left;
        }

        & > span:nth-child(2) {
          flex: 6;
          color: #999999;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      & > div:first-child {
        border-bottom: 1px solid #eee;
      }

      & > div:last-child {
        padding: 13px 0 0;
      }
    }
  }

  .more {
    // background: #fff;
    margin: 13px;
    padding: 13px 0;
    border-radius: 5px;

    .more_title {
      color: #E33F44;
      text-align: center;
      font-weight: 500;
      position: relative;
    }

    .more_in {
      color: #999;
      font-weight: 400;
      position: absolute;
      right: 0;
      top: 0;
    }

    .more_ul {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0;

      & > div {
        background: #fff;
        width: 48%;
        margin: 5px 3px;
        border-radius: 8px;

        .item_img {
          width: 169px;
          height: 170px;

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        .item_info {
          padding: 10px;
          text-align: left;

          .goodsName {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 20px;
          }

          .needer {
            margin-top: 7px;

            & > span {
              font-size: 12px;
            }

            & > span:nth-child(2) {
              font-size: 12px;
              color: #E33F44;
              font-weight: 500;
            }
          }

          .originPrice {
            color: #999999;
            text-decoration: line-through;
            font-size: 13px;
            margin-top: 7px;
          }
        }
      }
    }
  }
}
</style>
